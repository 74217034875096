import React, { useEffect, FC } from 'react'
import {
  getCanonical,
  getLang,
  getLocale,
  getTranslations,
} from '../../utils/context'
import { formatMessage } from '../../utils/translations'
import { Helmet } from 'react-helmet'
import { snippets } from './brandProps'
import { getBrand } from '../../utils/context'
import { ISeo } from './type'
import { useLocation } from '@reach/router'

const Seo: FC<ISeo> = ({
  noindex,
  customCanonical,
  meta,
  hreflangs,
  richSnippets,
}) => {
  const translations = getTranslations()

  const location = useLocation()

  const getMetaImage = (image: string) => {
    if (image.search('http') !== -1) {
      return image
    }
    return `${process.env.GATSBY_CDN_HOST}/${image}.png`
  }

  useEffect(() => {
    // Ensure window is defined and dataLayer exists
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'page-viewed': meta?.title,
      })

      // Load rudderanalytics script if doesn't exists (tracking)
      const hasRudderScript = document.getElementById('rudder')

      if (!hasRudderScript) {
        setTimeout(() => {
          const rudder = document.createElement('script')
          rudder.innerHTML = `
        var script = document.createElement('script');
        script.setAttribute('src', 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js');
        document.head.append(script);
        rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(a){return function(){rudderanalytics.push([a].concat(Array.prototype.slice.call(arguments)))}}(method)}rudderanalytics.load("${process.env.GATSBY_RUDDERSTACK_KEY}","${process.env.GATSBY_RUDDERSTACK_HOST}"),rudderanalytics.reset();
        // window?.branch is injected in Netlify (eg: https://app.netlify.com/sites/assoconnect-showcase-en-us/settings/deploys#post-processing)
        if(window?.branch) {
          rudderanalytics.identify(rudderanalytics.getAnonymousId(), {
            branch: window?.branch,
          })
        }
      `
          rudder.id = 'rudder'
          document.body.appendChild(rudder)

          window?.rudderanalytics?.page?.()
        }, 1500)
      }

      if (hasRudderScript) {
        window?.rudderanalytics?.page?.()
      }
    }
  }, [meta?.title])

  const brand = getBrand()

  return (
    <Helmet>
      <title>{meta.title}</title>

      {hreflangs?.map((hreflang, i) => (
        <link
          key={i}
          rel="alternate"
          href={hreflang.href}
          hrefLang={hreflang.lang}
        />
      ))}

      <link rel="canonical" href={getCanonical(location, customCanonical)} />

      {noindex && <meta name="robots" content="noindex,nofollow" />}
      {noindex && <meta name="googlebot" content="noindex,nofollow" />}

      <html lang={getLang()} />

      <meta property="og:locale" content={getLocale()} />
      <meta property="og:type" content="website" />

      <meta
        property="og:site_name"
        content={formatMessage('site_name_human', translations)}
      />

      <meta property="og:title" content={meta.title} />
      <meta name="description" content={meta.description} />
      <meta property="og:description" content={meta.description} />

      <meta
        property="og:image"
        content={
          meta?.image !== undefined
            ? getMetaImage(meta.image)
            : `${process.env.GATSBY_CDN_HOST}/components/head/${formatMessage(
                'site_name',
                translations
              )}-600x600`
        }
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />

      <link
        rel="preconnect"
        href="https://web-assoconnect-frc-prod-cdn-endpoint-showcase.azureedge.net"
      />
      <link rel="preconnect" href="https://cdn.rudderlabs.com" />

      {richSnippets ? (
        <script defer type="application/ld+json">
          {richSnippets}
        </script>
      ) : (
        <script defer type="application/ld+json">
          {snippets[brand]}
        </script>
      )}

      <script
        defer
        id="hbspt"
        src="https://js.hscta.net/cta/current.js"
      ></script>

      {process.env.GATSBY_DEV_SSR !== 'true' && (
        <noscript>
          {`
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}"
                  height="0"
                  width="0"
                  style="display:none;visibility:hidden"
                ></iframe>
              `}
        </noscript>
      )}
    </Helmet>
  )
}

export default Seo
