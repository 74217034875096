import React from 'react'
import { MiddleTitle, Text, AdaptiveImage, Box, BulletList } from '../../'
import { Flex } from '@rebass/grid'

/**
 * Component
 */
const SliderListSlide = ({ title, image, list, id }) => (
  <Flex flexWrap="wrap" id={id}>
    <Box className="pb-medium pb-big"></Box>
    <Box width={1 / 2}>
      {image.maxDpi ? (
        <AdaptiveImage
          src={image.src}
          alt={image.alt}
          maxDpi={image.maxDpi}
          visibleByDefault
          isFullSize
        />
      ) : (
        <AdaptiveImage
          src={image.src}
          alt={image.alt}
          visibleByDefault
          isFullSize
        />
      )}
    </Box>
    <Box width={1 / 2}>
      <MiddleTitle color="blue" align="left">
        {title}
      </MiddleTitle>
      <BulletList
        datas={list.map((item, i) => (
          <Text key={i}>{item}</Text>
        ))}
      />
    </Box>
  </Flex>
)

export default SliderListSlide
